<template>
  <v-container class="py-7">
    <div class="heading mb-7">Получено баллов</div>

    <template v-if="list && list.length">
      <div v-for="(item, i) in list" :key="i">
        <div class="bonuses-date">{{item.date}}</div>
        <v-row dense>
          <v-col class="bonuses-label">{{item.label}}</v-col>
          <v-col cols="auto" class="heading primary--text">+ {{item.points}}</v-col>
        </v-row>
        <v-divider class="mt-2 mb-4" />
      </div>
    </template>

    <div v-else>История отсутствует</div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "BonusesUp",

  computed: {
    list() {
      return this.bonuses?.up;
    },

    ...mapState("user", ["bonuses"])
  },

  async created() {
    const { commit, dispatch } = this.$store;
    commit("overlay/update", { active: true });
    await dispatch("user/loadBonusesUp");
    commit("overlay/update", { active: false });
  }
};
</script>

<style lang="scss" scoped>
.bonuses-date {
  font-size: 16px;
  font-weight: 600;
}
.bonuses-label {
  font-size: 14px;
  font-weight: 600;
}
</style>